'use client';

import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import {
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

type Props = {
  isVisible: boolean;
  storageKey?: string;
};

export const CollapseClient: FC<PropsWithChildren<Props>> = (props) => {
  const { isVisible, storageKey, children } = props;

  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const openValue = !!storageKey
      ? isVisible &&
        !!storageKey &&
        localStorage.getItem('maintenance-banner') !== storageKey
      : isVisible;
    setOpen(openValue);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    if (storageKey) {
      localStorage.setItem('maintenance-banner', storageKey);
    }
  }, [setOpen, storageKey]);

  if (!isOpen) return null;

  return (
    <Collapse in={isOpen}>
      <Alert
        severity="info"
        variant="outlined"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ backgroundColor: 'white', margin: 2 }}
      >
        {children}
      </Alert>
    </Collapse>
  );
};
